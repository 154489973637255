import Breadcrumb from '../breadcrumb/breadcrumb.component';
import './directory.styles.css';

const Directory = ({ currentPath, setCurrentPath, folders, files}) => {

  const currentFiles = files.filter(file => file.filepath === currentPath);
  // const currentFiles = files.filter(file => true);
  // console.log('currentFiles',currentFiles);
  const currentFolders = folders.filter(folder => folder.parentDir === currentPath);
  // const currentFolders = folders.filter(folder => true);
  // console.log('currentFolders',currentFolders);

  return (
    <div className="w3-container w3-card w3-white">
      <h2 className="w3-text-grey w3-padding-16"><i className="fa fa-certificate fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Directory</h2>

      <div className="w3-container">
        <h5 className="w3-opacity"><b>
          <Breadcrumb currentPath={currentPath} setCurrentPath={setCurrentPath} /></b></h5>
        <ul>
          {currentFolders.map( (value, i) => (
            <li className="w3-text-teal" key={i} onClick={() => setCurrentPath(value.fullPath)}>{value.foldername}</li>
          ))}
          {currentFiles.map( (value, i) => (
            <li className="w3-opacity" key={i}><a href={value.downloadLink}>{value.filename}</a></li>
          ))}
        </ul>
        <hr />
      </div>
      

    </div>
  );
};

export default Directory;