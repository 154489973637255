const ClientUserData = ({ initialData }) => {
  return initialData ? (
    <div>
      <h2>Client Data:</h2>
      {/* <p>Name: {initialData.data.clientInfo.client_name}</p> */}
      {/* <p>Sending domain: {initialData.data.clientInfo.client_sending_domain}</p> */}
      <p>Email: {initialData.data.userInfo.userEmail}</p>
    </div>
  ) : (
    <div>
      <br />
      <h4>No Client Data found</h4>
    </div>
  );
}

export default ClientUserData;