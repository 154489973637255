import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { CognitoIdentityProviderClient, GetUserCommand } from "@aws-sdk/client-cognito-identity-provider";

import userpool from '../userpool';


const cognito = new CognitoIdentityProviderClient({ region: "us-east-2" });

export const authenticate = (Email, Password)=>{

  return new Promise((resolve,reject)=>{

    const user = new CognitoUser({
      Username:Email,
      Pool:userpool
    });
    console.log("user",user);

    const authDetails = new AuthenticationDetails({
      Username:Email,
      Password
    });
    console.log("authDetails",authDetails);

    user.authenticateUser(authDetails, {
      onSuccess:(result)=>{
        console.log("login successful", result);
        resolve(result);
      },
      onFailure:(err)=>{
        console.log("login failed",err);
        reject(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.log("newPasswordRequired:", userAttributes, requiredAttributes);
        delete userAttributes.email_verified;
        delete userAttributes.email;
        user.completeNewPasswordChallenge(Password, userAttributes, {
          onSuccess: (result) => {
            console.log("login successful");
            resolve(result);
          },
          onFailure: (err) => {
            console.log("login failed", err);
            reject(err);
          }
        });
      },
      totpRequired: () => {
        const token = prompt('Please enter your TOTP code');
        user.sendMFACode(token, {
          onSuccess: (result) => {
            window.location.href = '/dashboard';
          },
          onFailure: (err) => {
            alert('Incorrect TOTP code');
          }
        }, 'SOFTWARE_TOKEN_MFA')
      },
    });
  });
};

export const logout = () => {
    const user = userpool.getCurrentUser();
    user.signOut();
    window.location.href = '/';
};

export const getCurrentUser = () => {
  return userpool.getCurrentUser();
}

export const fetchCurrentAuthToken = () => {
  return new Promise((resolve, reject) => {
    var cognitoUser = userpool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log(err);
          reject(err);
        } else if (!session.isValid()) {
          console.log('Session is invalid');
          reject('Session is invalid');
        } else {
          resolve(session.getIdToken().getJwtToken());
        }
      });
    } else {
      console.log('No cognitoUser');
      reject('No cognitoUser');
    }
  });
}

export const fetchUserAttributes = () => {
  return new Promise((resolve, reject) => {
    var cognitoUser = userpool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log(err);
          reject(err);
        } else if (!session.isValid()) {
          console.log('Session is invalid');
          reject('Session is invalid');
        } else {
          cognitoUser.getUserAttributes(function(err, result) {
            if (err) {
              console.log(err);
              reject(err);
            } else {
              var attributes = {};
              for (var i = 0; i < result.length; i++) {
                if (result[i].getName().includes('custom:')) {
                  attributes[result[i].getName().substring(7)] = result[i].getValue();
                }else{
                  attributes[result[i].getName()] = result[i].getValue();
                }
              }
              resolve(attributes);
            }
          });
        }
      });
    } else {
      console.log('No cognitoUser');
      reject('No cognitoUser');
    }
  });
}

export const getUserSession = () => {
  return new Promise((resolve, reject) => {
    var cognitoUser = userpool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log(err);
          reject(err);
        } else if (!session.isValid()) {
          console.log('Session is invalid');
          reject('Session is invalid');
        } else {
          cognitoUser.getUserAttributes(async function(err, result) {
            if (err) {
              console.log(err);
              reject(err);
            } else {
              resolve(session);
            }
          });
        }
      });
    } else {
      console.log('No cognitoUser');
      reject('No cognitoUser');
    }
  });
}

// export const mfaEnabled = async (accessToken) => {
//   const command = new GetUserCommand({ AccessToken: accessToken });
//   const response = await cognito.send(command);
//   return (response.UserMFASettingList 
//     && response.UserMFASettingList.includes('SOFTWARE_TOKEN_MFA'));
// };
export const fetchMfaConfiguration = async () => {
  const userSession = await getUserSession();
  const accessToken = userSession.accessToken.jwtToken;
  const command = new GetUserCommand({ AccessToken: accessToken });
  const response = await cognito.send(command);
  if (response.UserMFASettingList 
  && response.UserMFASettingList.includes('SOFTWARE_TOKEN_MFA')){
    return true;
  }
  return false;
};