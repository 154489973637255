import './create-folder.styles.css';

const CreateFolder = ({folderName, handleInputChange, handleCreateFolderButtonClick, currentPath}) => {

  return (
    <div className="w3-container">
      <h5 className="w3-opacity"><b>Create Folder in {currentPath}:</b></h5>
      <input type="text" onChange={handleInputChange} value={folderName} className="create-folder-box" />
      <br />
      <button onClick={handleCreateFolderButtonClick}>
        Create Folder
      </button>
      <hr />
    </div>
  
  );
  };

export default CreateFolder;