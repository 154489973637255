import { Breadcrumbs, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import userpool from '../userpool'
import { logout, fetchCurrentAuthToken, fetchMfaConfiguration } from '../services/authenticate';
import axios from 'axios';

// import ClientData from '../components/client-data/client-data.component';
import ClientUserData from '../components/ClientUserData';
import FileData from '../components/FileData';

// import ClientFiles from '../components/get-files/get-files.component';
import MFA from '../components/mfa/mfa.component'
import Directory from '../components/directory/directory.component';
// import FileUploading from '../components/file-uploading/file-uploading.component';
// import FolderUploading from '../components/folder-uploading/folder-uploading.component';

// import ResultsPagination from './results-pagination/results-pagination.component';
import CreateFolder from './create-folder/create-folder.component';

const apiUrl = process.env.REACT_APP_API_URL;

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Dashboard = () => {
  const Navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [folderName, setFolderName] = useState('');
  
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);

  const [mfaEnabled, setMfaEnabled] = useState(false);

  const [currentPath, setCurrentPath] = useState('/');
  const [clientLoggedIn, setClientLoggedIn] = useState('');
  const [previousDirectory, setPreviousDirectory] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFolder, setIsLoadingFolder] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);


  useEffect(() => {
    const user = userpool.getCurrentUser();

    if (!user) Navigate('/login');

    const fetchClientData = async () => {
      try {
        const authToken = await fetchCurrentAuthToken();
        const response = await axios.get(`${apiUrl}/client-data`, {
          headers: { 'Authorization': authToken }
        });
        setInitialData(response);
        let clientNickname = capitalizeFirstLetter(response.data.userInfo.clientNickname);
        setClientLoggedIn(clientNickname);
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    const fetchClientFiles = async () => {
      try {
        const authToken = await fetchCurrentAuthToken();
        const response = await axios.get(`${apiUrl}/get-files`, {
          headers: { 'Authorization': authToken }
        });
        setFolders(response.data.clientFiles.folders);
        setFiles(response.data.clientFiles.files);
      } catch (error) {
        console.error('Error fetching client files:', error);
      }
    };

    const fetchMfaStatus = async () => {
      try {
        const response = await fetchMfaConfiguration();
        setMfaEnabled(response);
      } catch (error) {
        console.error('Error fetching MFA status:', error);
      }
    };

    fetchClientData();
    fetchClientFiles();
    fetchMfaStatus();
  }, [Navigate, currentPath]);


  const handleLogout = () => {
    logout();
  };

  const onFileChange = event => {
    console.log("onFileChange", event.target.files);
    setSelectedFile(event.target.files[0]);
  };


  const onFolderChange = event => {
    console.log("onFolderChange", event.target);
    const files = event.target.files;
    console.log('files',files);
    const folders = Array.from(files).filter(file=>file.webkitRelativePath);
    console.log('FOLDERS',folders);
    setSelectedFolder(folders);
  };

  const onFolderUpload = async () => {
    console.log("onFolderUpload");
    try {
      if (!selectedFolder) {
        alert('Please select a folder');
        return;
      }
      setIsLoadingFolder(true);
      const authToken = await fetchCurrentAuthToken();
      for (const file of selectedFolder){
        if (file.type!==''){
          const response = await axios({
            method: 'POST',
            url: `${apiUrl}/upload`,
            params: {
              isFolder: true,
              filename: file.name,
              filetype: file.type,
              filekey: file.webkitRelativePath,
              size: file.size,
              currentFolder: currentPath,
            },
            headers: {
              Authorization: authToken,
            },
          });

          const presignedUrl = response.data.signedPutUrl;
          const result = await fetch(presignedUrl, {
            method: 'PUT',
            body: file,
          });
          console.log("Result::", file.webkitRelativePath, result);
          if (!(result.status === 200 && result.redirected === false)) {
            throw Error("non-200 response from signed URL");
          }
        } else {
          console.log(`Skipping file: ${file.webkitRelativePath}`)
        }
      }
   
      alert('Folder uploaded');
      setSelectedFolder(null);
      setIsLoadingFolder(false);
    } catch (error) {
      console.error('Error uploading file:', error);      
    }
  };

  const onFileUpload = async () => {
    console.log("onFileUpload");
    try {
      if (!selectedFile) {
        alert('Please select a file');
        return;
      }
      setIsLoading(true);
    
      const authToken = await fetchCurrentAuthToken();
      const response = await axios({
        method: 'POST',
        url: `${apiUrl}/upload`,
        params: {
          isFolder: false,
          filename: selectedFile.name,
          filetype: selectedFile.type,
          currentFolder: currentPath,
        },
        headers: {
          Authorization: authToken,
        },
      });
      const presignedUrl = response.data.signedPutUrl;
      const result = await fetch(presignedUrl, {
        method: 'PUT',
        body: selectedFile,
        headers: {
          'Content-Type': 'same-content-type-as-when-generating-url',
        },
      });
    
      if (!(result.status === 200 && result.redirected === false)) {
        throw Error("non-200 response from signed URL");
      }
    
      alert('File uploaded');
      setSelectedFile(null);
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }

  const handleInputChange = (event) => {
    setFolderName(event.target.value);
  };

  
  
  const handleCreateFolderButtonClick = async (event) => {
    console.log('EVENT', event);
    try {
      const authToken = await fetchCurrentAuthToken();
      const response = await axios({
        method: 'POST',
        url: `${apiUrl}/create-folder`,
        params: {
          foldername: folderName,
          currentFolder: currentPath,
        },
        headers: {
        Authorization: authToken,
        },
      });
      if (!(response.status === 200)) {
        throw Error("non-200 response from signed URL", response);
      }
      alert('Folder created');
      setFolderName('');
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  return (
    <>
      <div className="w3-row-padding">

      {/* <!-- Left Column --> */}
      <div className="w3-third">
        <div className="w3-white w3-text-grey w3-card-4">
          <div className="w3-display-container">
            <div className="w3-display-bottomleft w3-container w3-text-black">
              <h2>DASHBOARD</h2>
            </div>
          </div>
          <div className="w3-container">
            <Button
              style={{margin:"10px"}}
              variant='contained'
              onClick={handleLogout}
            >
              Logout
            </Button>

            <MFA 
              mfaEnabled={mfaEnabled}
              setMfaEnabled={setMfaEnabled}/>

            <ClientUserData initialData={initialData} />

            <FileData selectedFile={selectedFile} />

          </div>
        </div>
      </div>

      {/* <!-- Right Column --> */}
      <div className="w3-twothird">

        <div className="w3-container w3-card w3-white w3-margin-bottom">
          <h2 className="w3-text-grey w3-padding-16">
            <i className="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>Welcome to Dashboard {clientLoggedIn}
          </h2>

          
          {/* <FileUploading
            onChange={onFileChange}
            onClick={onFileUpload}
            currentPath={currentPath}
            isLoading={isLoading}
          /> */}
          <div className="w3-container">
            <h5 className="w3-opacity"><b>File Upload in {currentPath}</b></h5>
            <input type="file" onChange={onFileChange} />
            <button onClick={onFileUpload}>Upload!</button>
            <hr />
          </div>
          
          {/* <FolderUploading
            onChange={onFolderChange}
            onClick={onFolderUpload}
            currentPath={currentPath}
            isLoadingFolder={isLoadingFolder}/> */}
            <div className="w3-container">
              <h5 className="w3-opacity"><b>Folder Upload in {currentPath}</b></h5>
              <input type="file" directory="" webkitdirectory="" onChange={onFolderChange} />
              <button onClick={onFolderUpload}>Upload!</button>
              <hr />
            </div>

          <CreateFolder 
            handleInputChange={handleInputChange} 
            handleCreateFolderButtonClick={handleCreateFolderButtonClick} 
            folderName={folderName} 
            currentPath={currentPath}/>

        </div>

        <Directory 
          currentPath={currentPath} 
          setCurrentPath={setCurrentPath} 
          folders={folders}
          files={files}
          previousDirectory={previousDirectory}
          setPreviousDirectory={setPreviousDirectory}/>
      </div>

    </div>
    </>
  )
}

export default Dashboard