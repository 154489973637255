import React, { useState, useContext } from "react";
import { 
  getUserSession, 
  fetchCurrentAuthToken, 
  fetchUserAttributes, 
  getCurrentUser,
  mfaEnabled, 
  // fetchMfaConfiguration,
} from '../../services/authenticate';
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const MFA = ({ mfaEnabled, setMfaEnabled }) => {

  const [session, setSession] = useState(null);
  const [userCode, setUserCode] = useState('');
  const [imageMFA, setImageMFA] = useState('');
  const [disableCode, setDisableCode] = useState('');


  

  // useEffect(() => {
  //   const fetchSession = async () => {
  //     try {
  //       const session = await getUserSession();
  //       const accessToken = session.accessToken.jwtToken;
  //       const mfaList = await mfaEnabled(accessToken);
  //       setSession(session);
  //       setEnabled(mfaList);
  //     } catch (error) {
  //       console.error('Error fetching session:', error);
  //     }
  //   };
  //   fetchSession();
  // }, []);

  
  
  const getQRCode = async () => {
    try {
      const session = await getUserSession();
      const accessToken = session.accessToken.jwtToken;
      const authToken = await fetchCurrentAuthToken();

      const response = await axios({
        method: 'GET',
        // url: `${apiUrl}/manage-mfa`,
        // url: `${apiUrl}/mfa-get-qr`,
        url: "https://vdh4l26xhj.execute-api.us-east-2.amazonaws.com/Dev/mfa",
        // url: `${apiUrl}/mfa`,
        params: {
          accessToken: accessToken,
        },
        headers: {
          Authorization: authToken,
        },
      });

      console.log('getQRCodeResponse::', response.data.qr);
      setImageMFA(response.data.qr);
    } catch (error) {
      console.error('Error getting QR Code:', error);
    }
  };




  const disableMFA = async () => {

    if (!/^\d{6}$/.test(disableCode) || !disableCode){
      alert('User code must be 6 digits');
      return;
    }

    try {
      const session = await getUserSession();
      const authToken = await fetchCurrentAuthToken();
      // const currentUser = getCurrentUser();
      const response = await axios({
        method: 'DELETE', // MfaDisable
        // // url: `${apiUrl}/mfa`,
        url: "https://vdh4l26xhj.execute-api.us-east-2.amazonaws.com/Dev/mfa",
        params: {
          accessToken: session.accessToken.jwtToken,
          userCode: disableCode,
        },
        headers: {
          Authorization: authToken,
        },
      });
      console.log(response.data);
      const body = JSON.parse(response.data.body);
      alert(body.message);
      setDisableCode('');
    } catch (error) {
      console.error('Error disabling MFA:', error);
    }
  };

  const handleInputUserCodeChange = (event) => {
    setUserCode(event.target.value);
  };

  const handleInputDisableCodeChange = (event) => {
    setDisableCode(event.target.value);
  };


  const handleUserCodeConfirmButtonClick = async () => {
    console.log('handleUserCodeConfirmButtonClick');

    if (!userCode) {
      alert('Please enter a user code');
      return;
    }

    if (!/^\d{6}$/.test(userCode)){
      alert('User code must be 6 digits');
      return;
    }

    try {
      console.log(userCode);
      const session = await getUserSession();
      const authToken = await fetchCurrentAuthToken();
      const currentUser = getCurrentUser();

      const response = await axios({
        method: 'POST', // MfaValidateCode
        // url: `${apiUrl}/mfa`,
        url: "https://vdh4l26xhj.execute-api.us-east-2.amazonaws.com/Dev/mfa",
        params: {
          accessToken: session.accessToken.jwtToken,
          userCode: userCode,
        },
        headers: {
          Authorization: authToken,
        },
      });
      console.log('MFA Code Validation Response', response.data);
      if (response.data.statusCode===200) {
        setMfaEnabled(true);
      } else {
        const errorBody = JSON.parse(response.data.body);
        console.log(errorBody);
      }

    } catch (error) {
      console.error('Error enabling MFA:', error);
    }
  };

  return(
    <div>
      <h1> Multifactor Authentication </h1>
      
      { mfaEnabled ? (
        <div>
          <div>MFA is enabled</div> 
          <div>
            <input 
              type="text" 
              onChange={handleInputDisableCodeChange}
              className="create-folder-box"
            />
            <button 
              onClick={disableMFA}>Disable MFA
            </button>
          </div>
        </div> )
        
        : imageMFA ? (
          <div>
            <h2>Scan the QR Code</h2>
            <img src={imageMFA} alt="QR Code" />
            <input 
              type="text" 
              onChange={handleInputUserCodeChange}
              className="create-folder-box"
            />
            <button onClick={handleUserCodeConfirmButtonClick}>
              Confirm Code
            </button>
              
          </div>)

        : <button 
            onClick={getQRCode}>Enable MFA
          </button>
      }
    </div>
  );
};

export default MFA;