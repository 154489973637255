
const FileData = ({ selectedFile }) => {
  return selectedFile ? (
    <div>
      <h2>File Details:</h2>
      <p>File Name: {selectedFile.name}</p>
      <p>File Type: {selectedFile.type}</p>
      <p>Last Modified: {selectedFile.lastModifiedDate.toDateString()}</p>
      <p>File Size: {selectedFile.size} bytes</p>
    </div>
  ) : (
    <div>
      <br />
      <h4>Choose a file before Pressing the Upload button</h4>
    </div>
  );
}

export default FileData;