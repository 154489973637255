const Breadcrumb = ({ currentPath, setCurrentPath }) => {
  let segments = currentPath.split('/').filter(segment => segment);
  return (
    <div>
      {segments.map((segment, i) => (
        <span key={i} onClick={() => setCurrentPath(`${segments.slice(0, i).join('/')}/`)}>{segment} / </span>
      ))}
    </div>
  );
};

export default Breadcrumb;



/*



*/